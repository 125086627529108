import { Dialog, Transition } from '@headlessui/react';
import { captureException } from '@sentry/react';
import { useForm } from '@tanstack/react-form-old';
import { useQuery } from '@tanstack/react-query';
import { Button, DateInput } from '@thedealersconcierge/components';
import {
  PrequalApplicationSchema,
  PrequalFormSchema
} from '@thedealersconcierge/lib/codecs/schema/prequalApplication';
import { socialSecurityNumber } from '@thedealersconcierge/lib/codecs/validation/socialSecurityNumber';
import { format, parse } from 'date-fns';
import { useAtom } from 'jotai';
import { Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { z } from 'zod';
import { cognitoRequestOtpAction } from '~/actions/auth/cognitoRequestOtpAction';
import { cognitoVerifyOtpAction } from '~/actions/auth/cognitoVerifyOtpAction';
import { hasExistingUserAction } from '~/actions/auth/hasExistingUser';
import requestOtpExistingUserDEVAction from '~/actions/auth/requestOtpExistingUserDEVAction';
import verifyOtpResponseAction from '~/actions/auth/verifyOtpResponseAction';
import Modal from '~/components/Modal';
import Signature from '~/components/Signature';
import Spinner from '~/components/Spinner';
import CircleCheckMarkIcon from '~/components/icons/CircleCheckMarkIcon';
import Checkbox from '~/components/inputs/Checkbox';
import DropDown from '~/components/inputs/DropDown';
import PhoneNumberInput from '~/components/inputs/PhoneNumberInput';
import SocialSecurityNumberInput from '~/components/inputs/SocialSecurityNumberInput';
import TextInput from '~/components/inputs/TextInput';
import config from '~/config';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { gqlMutationClient, gqlQueryClient } from '~/lib/backend';
import dealershipQuery from '~/queries/dealershipQuery';
import { useParams } from '~/router';
import { authStateAtom } from '~/state/auth';

const PrequalData = PrequalApplicationSchema.pick({
  firstName: true,
  middleName: true,
  lastName: true,
  socialSecurityNumber: true,
  street: true,
  apartmentDetails: true,
  state: true,
  city: true,
  zip: true
});

PrequalApplicationSchema;

type PrequalData = z.TypeOf<typeof PrequalData>;

const FormType = PrequalData.and(
  z.object({
    confirmNotAffectCreditScoreAndPrequalConsented: z.boolean(),
    hasCommunicationsConsented: z.boolean(),
    phoneNumber: z.string(),
    email: z.string(),
    birthdate: z.date().nullable()
  })
);

type FormType = z.TypeOf<typeof FormType>;

export default function WebPrequalPage() {
  const { dealershipSlug } = useParams('/prequal/:dealershipSlug');
  const { t, i18n } = useTranslation();
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  // Error when logging in/verifying OTPs
  const [loginError, setLoginError] = useState<string | null>(null);
  const [loggingIn, setLoggingIn] = useState(false);
  const [code, setCode] = useState('');
  const [, setAuthState] = useAtom(authStateAtom);
  const { data: dealership, isLoading } = useQuery(
    dealershipQuery({ slug: dealershipSlug })
  );
  const { data: publicData } = useQuery({
    queryKey: ['public'],

    // We don't expect that this page will change between window switches.
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryFn: () => {
      return gqlQueryClient()({
        public: {
          ipAddress: true
        }
      });
    }
  });

  const [error, setError] = useState<string | null>(null);
  const [signature, setSignature] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // We use this to commit the form values when logging in
  // This is more convenience as we could have read the values out of the form
  // but it is darn ugly with all the getter functions we'd have to call
  const [submittedFormValues, setSubmittedFormValues] = useState<
    [FormType, PrequalFormSchema] | null
  >(null);

  const submitOtp = async (email: string, otp: string) => {
    try {
      setLoginError(null);
      setLoggingIn(true);

      if (config.useCognito) {
        await cognitoVerifyOtpAction(otp);
      } else {
        const verifyOtpResponse = await verifyOtpResponseAction(email, otp);
        if (!verifyOtpResponse.jwt) {
          throw new Error('No JWT returned');
        }
        setAuthState({ jwt: verifyOtpResponse.jwt });
      }

      if (!submittedFormValues) {
        throw new Error('No form data was submitted');
      }

      // Check if we need to create a transaction for an existing user
      const [value, formData] = submittedFormValues;
      const data = await gqlMutationClient()({
        submitPrequalApplicationAndCreateUser: [
          {
            dealershipId: dealership?.dealership?.id ?? 'no-dealership-fetched',
            email: value.email,
            phoneNumber: value.phoneNumber,
            formData: formData
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationSubmitPrequalApplicationAndCreateUserSuccess': {
              data: {
                status: true
              }
            }
          }
        ]
      });

      if (
        data.submitPrequalApplicationAndCreateUser?.__typename !==
        'MutationSubmitPrequalApplicationAndCreateUserSuccess'
      ) {
        throw new Error(data.submitPrequalApplicationAndCreateUser?.message);
      }

      // All is good, full success
      setAuthState(null);
      setIsOtpModalOpen(false);
      setIsSubmitted(true);
    } catch (e) {
      console.error(e);
      captureException(e);
      setLoginError('Could not login');
    }
  };

  const form = useForm<FormType, unknown>({
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',

      birthdate: null,
      socialSecurityNumber: '',

      street: '',
      apartmentDetails: '',
      city: '',
      state: '',
      zip: '',

      phoneNumber: '',
      email: '',

      confirmNotAffectCreditScoreAndPrequalConsented: false,
      hasCommunicationsConsented: false
    },

    onSubmit: async (value) => {
      try {
        if (!signature) {
          throw new Error('No signature set');
        }

        const d: Omit<PrequalFormSchema, 'birthdate'> & {
          birthdate: Date | null; // Setting this here explicitly because the PrequalFormSchema is reused in a context where this has to be non-null
        } = {
          state: value.state,
          suffix: '',
          middleName: value.middleName ?? '',
          birthdate: value.birthdate
            ? new Date(`${format(value.birthdate, 'yyyy-MM-dd')}T00:00:00.000Z`) // The prequal schema works with dates. We have to make sure it's a UTC date
            : null,
          dateTime: format(new Date(), 'MM/dd/yyyy hh:mm a'),
          name: `${value.firstName} ${value.lastName}`.trim(),
          deviceId: window.navigator.userAgent,
          street: value.street,
          apartmentDetails: value.apartmentDetails,
          city: value.city,
          zip: value.zip,
          signature: signature,
          socialSecurityNumber: value.socialSecurityNumber,
          firstName: value.firstName,
          lastName: value.lastName,

          ipAddress: publicData?.public?.ipAddress ?? 'unknown'
        };
        // Just for good measure!
        const formData = PrequalFormSchema.parse(d);

        setSubmittedFormValues([value, formData]);

        const hasExistingUser = await hasExistingUserAction(value.email);

        if (!hasExistingUser) {
          const response = await gqlMutationClient()({
            submitPrequalApplicationAndCreateUser: [
              {
                dealershipId:
                  dealership?.dealership?.id ?? 'no-dealership-fetched',
                email: value.email,
                phoneNumber: value.phoneNumber,
                formData: formData
              },
              {
                __typename: true,
                '...on GraphQLError': {
                  message: true
                },
                '...on MutationSubmitPrequalApplicationAndCreateUserSuccess': {
                  data: {
                    status: true
                  }
                }
              }
            ]
          });

          if (
            response.submitPrequalApplicationAndCreateUser?.__typename ===
            'MutationSubmitPrequalApplicationAndCreateUserSuccess'
          ) {
            setIsSubmitted(true);
          } else {
            setError(
              response.submitPrequalApplicationAndCreateUser?.message ??
                'An error happened'
            );
          }
        } else {
          if (config.useCognito) {
            await cognitoRequestOtpAction(value.email);
          } else {
            await requestOtpExistingUserDEVAction(value.email);
          }

          setIsOtpModalOpen(true);
        }
      } catch (e) {
        alert('An error happened');
        console.error(e);
        captureException(e);
      }
    }
  });

  // When designing this, please take into account that it is being shown in an iFrame.
  return (
    <>
      <Modal
        isOpen={isOtpModalOpen}
        title={t('Verification')}
        className="p-6 mx-10"
        // We allow no control for the component
        onClose={() => {}}
      >
        <div className="flex flex-col items-center space-y-6">
          <div className="space-y-4">
            <p>
              {t(
                'Our records show that you already hold an account. We have sent a verification code to your phone and email.'
              )}
            </p>

            <div className="space-y-2">
              <TextInput
                placeholder={t('Code')}
                labelText={t('Code')}
                subtitleText={t('Code')}
                required
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
            </div>
          </div>

          {loginError && (
            <div className="flex bg-negative-primary w-full py-1 rounded-md text-primary-inverse  items-center justify-center">
              <p>{loginError}</p>
            </div>
          )}

          <Button
            label="Submit"
            dataTestId="prequal-create-submit-otp"
            onClick={() => {
              void submitOtp(form.getFieldValue('email'), code);
            }}
            disabled={code.length !== 6}
            isLoading={loggingIn}
          />
        </div>
      </Modal>
      {isSubmitted && (
        <Transition appear show={isSubmitted} as={Fragment}>
          {/* Can not close */}
          <Dialog as="div" className="relative z-30" onClose={() => null}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    className={
                      'w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-6'
                    }
                  >
                    <div
                      className="py-4 text-center text-heading-3"
                      data-test-id="web-prequal-success"
                    >
                      <Dialog.Title className="">
                        Your Prequalification Application Was Submitted
                        Successfully!
                      </Dialog.Title>
                    </div>

                    <p className="flex justify-center">
                      <CircleCheckMarkIcon className="text-[#219653] w-8 h-8" />
                    </p>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
      {isLoading && (
        <div className="flex w-screen h-screen items-center justify-center">
          <Spinner />
        </div>
      )}
      {!isLoading && !dealership?.dealership?.hasEnabledPrequalFormService && (
        <div className="flex w-screen h-screen items-center justify-center">
          <h1>No such dealership</h1>
        </div>
      )}
      {!isLoading &&
        Boolean(dealership?.dealership?.hasEnabledPrequalFormService) && (
          <div className="w-screen h-screen overflow-auto">
            <form.Provider>
              <form
                className="flex justify-center"
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  void form.handleSubmit();
                }}
              >
                <div className="flex flex-col max-w-[800px] divide-y">
                  {/* Heading section */}
                  <div className="p-6">
                    <h2 className="text-heading-2">
                      {t('Pre-Qualification Application')}
                    </h2>

                    <p>
                      {t(
                        'Once this form has been filled out, you will not be able to edit it. Please read the information below: This next step allows the dealer to view your credit score and accurately check what programs are available for your purchase. Note: This action will not affect your credit score and is not considered a Hard Inquiry on your credit file.'
                      )}
                    </p>
                  </div>

                  {error && (
                    <div className="bg-negative-secondary text-negative rounded-lg p-4 my-4">
                      {error}
                    </div>
                  )}

                  {/* Form section */}
                  <div className="p-6 flex flex-col space-y-2 bg-[#F9FAFC]">
                    <h2 className="text-heading-2">Basic Information</h2>

                    {/* Name line */}
                    <div className="grid grid-cols-6 md:grid-cols-12 gap-4 items-end">
                      <form.Field name="firstName">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-first-name"
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('First Name')}
                              placeholder={t('First Name')}
                              subtitleText={t('First Name')}
                              required
                              // disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="col-span-6 md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="middleName">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-middle-name"
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('Middle Name')}
                              subtitleText={t('Middle Name')}
                              placeholder={t('Middle Name')}
                              // disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="col-span-6 md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="lastName">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-last-name"
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('Last Name')}
                              placeholder={t('Last Name')}
                              subtitleText={t('Last Name')}
                              required
                              // disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="col-span-6 md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      {/* Birthday SSN */}
                      <form.Field
                        name="socialSecurityNumber"
                        onBlur={(ssn) => {
                          if (!socialSecurityNumber().safeParse(ssn).success) {
                            return 'Invalid social security number';
                          }

                          return undefined;
                        }}
                      >
                        {(field) => {
                          return (
                            <SocialSecurityNumberInput
                              dataTestId="web-prequal-ssn"
                              value={field.state.value}
                              fieldName={field.name}
                              subtitleText={t('Social Security Number')}
                              placeholder={t('Social Security Number')}
                              error={field.state.meta.touchedErrors
                                .at(0)
                                ?.toString()}
                              required
                              onBlur={field.handleBlur}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              setFieldValue={(_fieldName, value) => {
                                form.setFieldValue(field.name, value);
                              }}
                              containerClassName="col-span-6"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="birthdate">
                        {(field) => {
                          return (
                            <DateInput
                              dataTestId="web-prequal-birthdate"
                              value={
                                field.state.value
                                  ? format(field.state.value, 'yyyy-MM-dd')
                                  : ''
                              }
                              label={t('Date of Birth')}
                              placeholder={t('Date of Birth')}
                              required
                              disabled={isLoading}
                              errorMessage={field.state.meta.touchedErrors
                                .at(0)
                                ?.toString()}
                              onBlur={field.handleBlur}
                              onChange={(updatedDate) => {
                                field.handleChange(
                                  parse(updatedDate, 'yyyy-MM-dd', new Date())
                                );
                              }}
                              className="col-span-6"
                            />
                          );
                        }}
                      </form.Field>

                      {/* Address */}
                      <form.Field name="street">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-street"
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('Street Address')}
                              placeholder={t('Street Address')}
                              subtitleText={t('Street Address')}
                              required
                              // disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="col-span-6"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="apartmentDetails">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-apartment-details"
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('Apartment Details')}
                              placeholder={t('Suite, Apartment #')}
                              subtitleText={t('Apartment Details')}
                              // disabled={
                              //   isSubmitting ||
                              //   !values.acknowledgesInformationUnderstanding
                              // }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="col-span-6"
                            />
                          );
                        }}
                      </form.Field>

                      {/* City, state zip */}

                      <form.Field name="city">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-city"
                              fieldName={field.name}
                              value={field.state.value}
                              labelText="City"
                              placeholder="City"
                              subtitleText="City"
                              required
                              // disabled={
                              //   isSubmitting ||
                              //   !values.acknowledgesInformationUnderstanding
                              // }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="col-span-6 md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="state">
                        {(field) => {
                          return (
                            <DropDown
                              dataTestId="web-prequal-state"
                              fieldName={field.name}
                              value={field.state.value}
                              options={stateOptions}
                              labelText={t('State')}
                              placeholder={t('State')}
                              subtitleText={t('State')}
                              required
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="col-span-6 md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="zip">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-zip"
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('ZIP Code')}
                              placeholder={t('ZIP Code')}
                              subtitleText={t('ZIP Code')}
                              required
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="col-span-6 md:col-span-4"
                            />
                          );
                        }}
                      </form.Field>

                      {/* phone, email */}

                      <form.Field name="phoneNumber">
                        {(field) => {
                          return (
                            <PhoneNumberInput
                              dataTestId="web-prequal-phone-number"
                              fieldName={field.name}
                              value={field.state.value}
                              placeholder={t('Phone Number')}
                              subtitleText={t('Phone Number')}
                              onChange={(value) => {
                                field.handleChange(value?.toString() ?? '');
                              }}
                              onBlur={field.handleBlur}
                              error={field.state.meta.touchedErrors.at(0)}
                              required
                              containerClassName="col-span-6"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="email">
                        {(field) => {
                          return (
                            <TextInput
                              dataTestId="web-prequal-email"
                              fieldName={field.name}
                              value={field.state.value}
                              isEmail
                              labelText={t('Email')}
                              placeholder={t('Email')}
                              subtitleText={t('Email')}
                              required
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(
                                  e.target.value.toLocaleLowerCase().trim()
                                );
                              }}
                              containerClassName="col-span-6"
                            />
                          );
                        }}
                      </form.Field>
                    </div>
                  </div>

                  {/* Consent section */}
                  <div className="p-6 space-y-4">
                    <h2 className="text-heading-2">
                      {t('Our Commitment to Privacy')}
                    </h2>
                    <p>
                      <Trans t={t}>
                        All information stored in our database is secure and is
                        strictly confidential. Your personal and credit
                        information will only be used to fulfill your request
                        and in accordance with our{' '}
                        <a
                          target="_blank"
                          href="https://files.mytdc.net/privacy-policy-dealergenix-jan2024.pdf"
                          className="text-blue-500 hover:underline"
                        >
                          Privacy Policy
                        </a>
                        .
                      </Trans>
                    </p>
                    <form.Field
                      name="confirmNotAffectCreditScoreAndPrequalConsented"
                      validator={{
                        onChange: z.boolean().refine((val) => val, {})
                      }}
                      children={(field) => {
                        return (
                          <div className="flex flex-row space-x-2">
                            <Checkbox
                              dataTestId="web-prequal-confirm-not-affect-credit-score"
                              inputId={field.name}
                              value={field.state.value}
                              onChange={() => {
                                field.handleChange(!field.state.value);
                              }}
                              containerClassName="pt-1"
                            />
                            <p>
                              <Trans
                                t={t}
                                values={{
                                  dealershipName:
                                    dealership?.dealership?.name ??
                                    'the dealership'
                                }}
                              >
                                I understand that this is{' '}
                                <span className="italic font-bold">
                                  Not an Application for Credit
                                </span>
                                , and will not affect my credit score, and I
                                hereby consent to have my credit file accessed
                                for the purposes of prequalifying for a vehicle
                                loan through a soft inquiry, which will not
                                impact my credit score, while agreeing to the{' '}
                                <a
                                  target="_blank"
                                  className="text-blue-500 hover:underline"
                                  href={t('privacy-notice', {
                                    backendUrl: config.rawBackendUrl,
                                    dealershipSlug: dealershipSlug,
                                    lang: i18n.language,

                                    ns: 'files',
                                    defaultValue:
                                      '{{backendUrl}}/document/{{dealershipSlug}}/{{lang}}/privacy-notice.pdf'
                                  })}
                                >
                                  Privacy Notice
                                </a>{' '}
                                and{' '}
                                <a
                                  target="_blank"
                                  className="text-blue-500 hover:underline"
                                  href="https://files.mytdc.net/prequal-terms-and-conditions.pdf"
                                >
                                  Disclosures
                                </a>{' '}
                                and acknowledging that I may be contacted by{' '}
                                <span className="italic font-bold">
                                  {'{{dealershipName}}'}
                                </span>{' '}
                                and that I might not prequalify based on the
                                prequalification criteria.
                              </Trans>
                            </p>
                          </div>
                        );
                      }}
                    />

                    <form.Field
                      name="hasCommunicationsConsented"
                      children={(field) => {
                        return (
                          <div className="flex flex-row space-x-2">
                            <Checkbox
                              dataTestId="web-prequal-communication-consent"
                              inputId={field.name}
                              value={field.state.value}
                              onChange={() => {
                                field.handleChange(!field.state.value);
                              }}
                              containerClassName="pt-1"
                            />

                            <div>
                              <p className="font-bold">
                                {t(
                                  'Electronic Disclosure Consent and Acknowledgement'
                                )}
                              </p>

                              <p className="text-secondary">
                                <Trans t={t}>
                                  I hereby consent and acknowledge that I will
                                  receive all required disclosures
                                  electronically via the TDC platform. I
                                  understand that access to these disclosures
                                  will be available through the TDC Buyer Portal
                                  at{' '}
                                  <a
                                    target="_blank"
                                    className="text-blue-500 hover:underline"
                                    href="https://app.mytdc.net/signin"
                                  >
                                    https://app.mytdc.net/signin
                                  </a>
                                  . By providing my consent, I agree to review
                                  and retain the provided electronic disclosures
                                  as necessary.
                                </Trans>
                              </p>
                            </div>
                          </div>
                        );
                      }}
                    />
                    <div>
                      {t('Signature')}
                      <Signature
                        dataTestIdOpenModal="web-prequal-signature-open-modal"
                        dataTestIdCanvas="web-prequal-signature-canvas"
                        dataTestIdSubmit="web-prequal-submit-signature"
                        onSignatureSubmit={setSignature}
                        existingSignature={signature}
                      />
                    </div>
                  </div>

                  {/* Submit */}
                  <div className="p-6 flex justify-end">
                    <form.Subscribe>
                      {(f) => (
                        <Button
                          label={t('Submit')}
                          dataTestId="web-prequal-submit"
                          variant="PRIMARY"
                          isLoading={f.isSubmitting}
                          disabled={
                            !(
                              f.isValid &&
                              f.values.hasCommunicationsConsented &&
                              f.values
                                .confirmNotAffectCreditScoreAndPrequalConsented &&
                              Boolean(signature)
                            )
                          }
                        />
                      )}
                    </form.Subscribe>
                  </div>
                </div>
              </form>
            </form.Provider>
          </div>
        )}
    </>
  );
}
