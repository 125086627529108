import { PrequalFormSchema } from '@thedealersconcierge/lib/codecs/schema/prequalApplication';
import { generatePdf } from '@thedealersconcierge/lib/pdf-gen';
import { format } from 'date-fns';
import { TFunction } from 'i18next';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { prequalForm } from '~/lib/form/prequal-form';
import { loadFontBytes } from '~/lib/pdf';
import uploadFileAction from '../../../actions/formSubmissions/uploadFileAction';

const generateAndUploadPrequalPdfWorkflow = async (
  t: TFunction,
  formData: PrequalFormSchema,
  dealershipName: string
) => {
  const pdf = await generatePdf(
    prequalForm(t),
    {
      ...formData,
      dealershipName,
      state:
        stateOptions.find((o) => o.value === formData.state)?.label ??
        formData.state,
      birthdate: format(formData.birthdate, 'MM/dd/yyyy'),
      middleName: formData.middleName ?? ''
    },
    false,
    undefined,
    loadFontBytes
  );
  const pdfBlob = new Blob([pdf]);
  const prequalFile = await uploadFileAction('pdf', 'application/pdf', pdfBlob);

  return prequalFile.file;
};

export default generateAndUploadPrequalPdfWorkflow;
